import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isPopupOpened } from './appRoutes';

export const usePopupOpened = (...ARDPathList) => {
  const location = useLocation();

  const [popupsOpened, setPopupsOpened] = useState(Array(ARDPathList.length).fill(false));

  useEffect(() => {
    const popupsOpened = ARDPathList.map((path) => isPopupOpened(path, location));
    setPopupsOpened(popupsOpened);
  }, [location]);

  return popupsOpened;
};
